const registrationModel = [
  {
    element: "text",
    name: "vorname",
    label: {
      de: "Vorname Baby",
      en: "First Name",
      fr: "Prénom",
      it: "Nome",
    },
    type: "textInputHalf",
    half: true,
    defaultValue: "",
    id: "contactform-name",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "text",
    name: "nachname",
    label: {
      de: "Nachname Baby",
      en: "Last Name",
      fr: "Nom",
      it: "Cognome",
    },
    type: "textInputHalf",
    half: true,
    defaultValue: "",
    id: "contactform-lastname",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "text",
    name: "strasse",
    label: {
      de: "Strasse",
      en: "Street/No.",
      fr: "Rue/No",
      it: "Via/N.",
    },
    type: "textInputHalf",
    half: true,
    defaultValue: "",
    id: "contactform-street",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "number",
    name: "plz",
    label: {
      de: "PLZ",
      en: "Postcode",
      fr: "NPA",
      it: "NPA",
    },
    type: "numberInputStyle",
    style: { width: "22.5%" },
    id: "contactform-postal",
    defaultValue: "",
    helperText: " ",
    minLength: 4,
    validation: "lengthError",
    error: false,
  },

  {
    element: "text",
    name: "ort",
    label: {
      de: "Ort",
      en: "Town",
      fr: "Localité",
      it: "Località",
    },
    type: "textReadOnly",
    id: "place-compare",
    defaultValue: "",
    helperText: " ",
    minLength: 2,
    validation: "lengthError",
    error: false,
  },

  {
    element: "number",
    name: "telefon",
    label: {
      de: "Telefon",
      en: "Phone",
      fr: "Téléphone",
      it: "Telefono",
    },
    type: "textInputHalf",
    half: true,
    id: "contactform-tel",
    defaultValue: "",
    helperText: " ",
    minLength: 10,
    validation: "phoneError",
    error: false,
  },

  {
    element: "email",
    name: "email",
    label: {
      de: "E-Mail",
      en: "E-Mail",
      fr: "E-Mail",
      it: "E-Mail",
    },
    type: "textInputHalf",
    half: true,
    id: "contactform-email",
    defaultValue: "",
    helperText: " ",
    validation: "emailError",
    error: false,
  },

  {
    element: "text",
    name: "geburtsdatum",
    label: {
      de: "Voraussichtlicher geburtstermin",
      en: "Expected date of birth",
      fr: "Date d’accouchement prévue",
      it: "Data prevista per il parto",
    },
    type: "datePicker",
    half: true,
    id: "contactform-appointment",
    defaultValue: "",
    helperText: " ",
    validation: "dateError",
    error: false,
  },

  {
    element: "select",
    name: "persons",
    label: {
      de: "Anzahl Personen im Haushalt",
      en: "Numbers of persons in household",
      fr: "Nombre de personnes dans le ménage",
      it: "Numero di persone in famiglia",
    },
    type: "selectInput",
    style: { width: "48%" },
    id: "persons-index",
    defaultValue: "",
    helperText: " ",
    validation: "selectError",
    error: false,
    options: [
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
    ],
  },
]

export default registrationModel
